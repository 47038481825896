import React, { useState, useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import useScript from '../service/useScript';

import useDeviceDetect from "../service/useDeviceDetect"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import LoadMoreButton from "../components/addon/loadMoreButton"
import NewsLetter from "../components/addon/newsLetter"
import EventsListingSection from "../components/pageSections/eventsListing"
import VideoModal from "../components/addon/videoModal"
import Loader from "../components/addon/Loader"

import {
formatEventDate,
formatEventRegDate,
monthFromYear,
dateToYear,
getBannerVideoThumbnail,
compareDates,
convertArrToStr
} from "../service/helper"
import API from "../api/events"
import DOCTOR_API from "../api/doctors"


const EventListing = (props) => {
    const { data } = props
    let bannerInfo = {}
    let metaTags = []
    let schema = []
    let canonicalUrl = {}
    const { isMobile } = useDeviceDetect()
    const defaultImageSrc = isMobile === true ? "/assets/images/placeholder-348x233.png" : "/assets/images/placeholder-635x233.png"
    let imageSrc = ""
    let altText = ""
    let banner_event_id = null
    let show_hero_section = null
    let eventEndDate = '';

    if (data && data?.allNodeEvent?.edges?.length > 0) {
        if (data?.allNodeEvent?.edges[0]?.node?.id) {
            bannerInfo = data?.allNodeEvent?.edges?.[0]?.node
            imageSrc = isMobile === true ? bannerInfo?.relationships?.image_mobile?.uri?.url : bannerInfo?.relationships?.image?.uri?.url
            altText = bannerInfo?.field_image?.alt || "Hinduja Hospital"      
            banner_event_id =  data?.allNodeEvent?.edges?.[0]?.node
            show_hero_section = data?.allNodeEvent?.edges?.[0]?.node?.field_keep_as_hero_event
            eventEndDate = data?.allNodeEvent?.edges?.[0]?.node?.eventEndDate
        }
    }

    if (data && data?.allNodeTemplatePages?.edges && data.allNodeTemplatePages.edges.length > 0) {
        metaTags = data.allNodeTemplatePages.edges[0]?.node?.metatags ? data.allNodeTemplatePages.edges[0].node.metatags : [] 
        schema = data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema ? data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema : []
        canonicalUrl = data.allNodeTemplatePages?.edges[0].node?.metatags_on_page || {}
    }
    
    if (eventEndDate != null && eventEndDate != '') {
        var inputDate = new Date(eventEndDate).setHours(0, 0, 0, 0);
        var toDay = new Date().setHours(0, 0, 0, 0);
        if(inputDate > toDay){
            console.log("Date is a future date");
            show_hero_section = true
        }else if(inputDate== toDay){
            console.log("Date is equal to today");
            show_hero_section = true
        }else{
            console.log("Date is a past date");
            show_hero_section = false
        }
    }

    useScript("/assets/js/eventsListing.js");
    useScript("/assets/js/custom_select.js");
    useScript("/assets/js/login-register.js");

    const [videoURL, setVideoURL] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [params ] = useState({
        limit: 3,
        offset: 0,
    })
    let [pastEventParams, setPastEventsParams] = useState({
        ...params,
        when: "past"
    })
    let [futureEventParams, setFutureEventsParams] = useState({
        ...params,
        when: "future"
    })
    let [filterOptions ] = useState([
        {
            id: 'all',
            label: 'All',
        },
        {
            id: 'Patient Connect',
            label: 'Patient Connect Event',
        },
        {
            id: 'Medical Events',
            label: 'Medical Events',
        },
        {
            id: 'Other Events',
            label: 'Other Events',
        }
    ])
    let [specialities, setSpecialities] = useState([])
    let [yearsOptions, setYearsOptions] = useState([])
    let [specPlaceholder ] = useState("All")
    let [defaultLimit ] = useState(5)
    let [archives, setArchives] = useState([])
    let [nextArchivesPage, setArchivesNextPage] = useState(null)
    let [archivesPageLimit, setArchivesPageLimit] = useState(defaultLimit)
    let [archivesSearchPageLimit ] = useState(10)
    let [loading, setLoading] = useState(false)
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [archivesQueryParams, setArchivesQueryParams] = useState({
        'page[limit]': archivesPageLimit,
        'page[offset]': 0
    })
    const [metaImage, setMetaImage] = useState("")
    const [selectedSpeciality, setSelectedSpeciality] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [showHeroRegBtn, setShowHeroRegBtn] = useState(false)
    let allMonths = [
        {id: '01', label: 'Jan'},
        {id: '02', label: 'Feb'},
        {id: '03', label: 'March'},
        {id: '04', label: 'April'},
        {id: '05', label: 'May'},
        {id: '06', label: 'June'},
        {id: '07', label: 'July'},
        {id: '08', label: 'Aug'},
        {id: '09', label: 'Sep'},
        {id: '10', label: 'Oct'},
        {id: '11', label: 'Nov'},
        {id: '12', label: 'Dec'},
    ]
    const [currentYear, setCurrentYear] = useState(null)
    const [monthsList, setMonthsList] = useState(allMonths)
    const [currentMonth, setCurrentMonth] = useState(null)
    const [yearsList, setYearsList] = useState([])
    useEffect(() => {
        const years = [new Date().getFullYear(), new Date().getFullYear() - 1] // Loading current and last year for archives years dropdown
        setYearsOptions(years)
        getSpecialities()
        // const json = {
        // 'filter[start-date-filter][condition][path]': 'field_event_date',
        // 'filter[start-date-filter][condition][operator]': '>=',
        // 'filter[start-date-filter][condition][value]': '2021-05-20'
        // }
        const videoImage = document.getElementById("eventBannerVideoThumbnail")
        if(videoImage && videoImage.style) {
            videoImage.style.height = '437px'
            videoImage.style.width = '635px'
        }
    }, [])

    const openVideoModal = (url) => {
        setVideoURL(url)
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }

    const getSpecialities = () => {
        DOCTOR_API.getSpecialities()
            .then(response => {
                if (response && response.length) {
                    let arr = [];
                    response.forEach(el => {
                        arr.push({
                            name: el?.name,
                            id: el?.name
                        })
                        if(el.children){
                            const t = el.children.map(c => {
                                return {
                                    name: c?.name,
                                    id: c?.name
                                }
                            })
                            arr = [...arr, ...t];
                        }
                    })
                    arr.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                    setSpecialities(arr)
                    if(typeof window.initSelect !== 'undefined') window.initSelect();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const setLoader = () => {
        setLoading(true)
        setShowNoDataText(false)
    }
    const getData = () => {
        setLoader()
        API.getEvents(archivesQueryParams)
        .then(response => {
            if (response && response?.data && response.data.length) {
                let arr = archives
                setArchives(arr.concat(response.data))
                setShowNoDataText(false)
            } else {
                setShowNoDataText(true)
            }
            setLoadMoreBtn(response?.links)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            setShowNoDataText(true)
        })
    }
    const setLoadMoreBtn = (links) => {
        if (links && links.next) {
            setArchivesNextPage(links.next?.href)
        } else setArchivesNextPage(null)
    }
    const loadMoreData = () => {
        setArchivesQueryParams({
            ...archivesQueryParams,
            'page[offset]': archivesQueryParams['page[offset]'] + archivesQueryParams['page[limit]'] 
        })
    }

    const filterByCategory = (value) => {
        setLoader()
        setArchives([])
        setArchivesPageLimit(archivesSearchPageLimit)
        setArchivesQueryParams({
            ...archivesQueryParams,
            'filter[category][condition][path]': 'field_category.name',
            'filter[category][condition][value]': value,
            'page[limit]': archivesSearchPageLimit,
            'page[offset]': 0
        })
    }
    const filterByYear = (value) => {
        setLoader()
        setArchives([])
        const firstDate = value + "-01-01"
        const lastDate = value + "-12-31"
        setArchivesPageLimit(archivesSearchPageLimit)
        setArchivesQueryParams({
        ...archivesQueryParams,
            'filter[date-group][group][conjunction]': 'AND',
            'filter[start-date-filter][condition][path]': 'field_event_date',
            'filter[start-date-filter][condition][operator]': '>=',
            'filter[start-date-filter][condition][memberOf]': 'date-group',
            'filter[end-date-filter][condition][path]': 'field_event_date',
            'filter[end-date-filter][condition][operator]': '<=',
            'filter[end-date-filter][condition][memberOf]': 'date-group',
            'filter[start-date-filter][condition][value]': firstDate,
            'filter[end-date-filter][condition][value]': lastDate,
            'page[limit]': archivesSearchPageLimit,
            'page[offset]': 0
        })
    }
    // const clearData = () => {
    // setArchives([])
    // setArchivesPageLimit(defaultLimit)
    // setArchivesQueryParams({
    // 'page[limit]': defaultLimit,
    // 'page[offset]': 0
    // })
    // }
    const setFilter = (filter) => {
        if (filter === 'all') {
            setPastEventsParams({
                ...params,
                when: "past"
            })
            setFutureEventsParams({
                ...params,
                when: "future"
            })
        } else {
            setPastEventsParams({...pastEventParams, "type": filter})
            setFutureEventsParams({...futureEventParams, "type": filter})
        }
    }
    const getVideoImage = () => {
        const url = getBannerVideoThumbnail(bannerInfo.video.url)
        if (url) {
            return url
        }
        return null
    }
    const playVideo = () => {
        const url = data?.allNodeEvent?.edges?.[0]?.node.video.url
        setShowModal(true)
        if (url) {     
            setVideoURL(url)
        }
    }

    const formatYearList = () => {
        const d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth()
        setCurrentYear(year)
        setCurrentMonth(month)
        let arr = []
        for (var i = year; i >= 2000; i--) { 
            // let obj = {id: i, label: i}
            arr.push(i)
        }
        // setYearsList(arr)
    }

    const onCategorySelect = (event) => {
        if (event.target.value) {
            setSelectedSpeciality(event.target.value)
        }
    }

    const onYearSelect = (event) => {
        const { value } = event.target
        setSelectedYear(value)
        setSelectedMonth(null)
        if(Number(value) === Number(currentYear)) {
            let index = allMonths.findIndex(el => Number(el?.id) === Number(currentMonth))
            setMonthsList(allMonths.slice(0, index+2))
        } else {
            setMonthsList(allMonths)
        }
    }

    const onMonthChange = (event) => {
        if (event.target.value) {
            setSelectedMonth(event.target.value)
        } 
    }

    const getArchivedEventsDataByQuery = () => {
        setArchiveLoading(true)
        let query = ''
        if(selectedMonth) query = query+'?month='+selectedMonth
        if(selectedYear) query = selectedMonth ? query+'&year='+selectedYear : query+'?year='+selectedYear
        if(selectedSpeciality) query = selectedMonth || selectedYear ? query+'&category='+selectedSpeciality : query+'?category='+selectedSpeciality
        API.getArchivedEventsData(query)
        .then(res => {
            if(res?.data?.length) {
                
                res?.data.sort(function(a, b){
                    if(a.eventEndDate > b.eventEndDate) { return -1; }
                    if(a.eventEndDate < b.eventEndDate) { return 1; }
                    return 0;
                })
                
                setArchives(res?.data)
            } else {
                setArchives([])
            }
            setArchiveLoading(false)
        })
        .catch(err => {
            console.log(err)
            setArchives([])
            setArchiveLoading(false)
        });
    }

    const parentCallback = (dateTime) => {
        console.log('Hero Event registrationDeadline null --============',bannerInfo?.registrationDeadline)
        let result = compareDates(dateTime, bannerInfo?.registrationDeadline) && bannerInfo.field_paid_event === true && bannerInfo?.registrationDeadline !== null
        setShowHeroRegBtn(result)
    }

    let todayDate = new Date().setHours(0, 0, 0, 0);
    archives = archives.filter( item => {
        let inputDate = new Date(item.eventEndDate).setHours(0, 0, 0, 0);
        return inputDate < todayDate
    }) 

    useEffect(() => {
        formatYearList();
    },[])

    useEffect(() => {
        getArchivedEventsDataByQuery()
    },[selectedMonth, selectedYear, selectedSpeciality])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [monthsList, yearsList, currentYear, selectedMonth, selectedYear])

    useEffect(() => {
        // getData()
    }, [archivesQueryParams])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [specialities])

    return (
<Layout>
    <Meta
    title="Events"
    files={
    {
    js: [],
    css: ["/assets/css/blog.css"]
    }
    }
    tags={{metaTags,canonicalUrl}}
    imgSrc = {metaImage}
    />
    <Schema schema={schema} />
    <main className="innerpage">
        {show_hero_section
        ? <section className="event_intro_latest section-bg section-py">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="video_container">
                            {
                            bannerInfo?.video?.url ?
                            <>
                            <img id="eventBannerVideoThumbnail" src={getVideoImage() || defaultImageSrc} alt={bannerInfo.title || ''} className="vid_img " />
                            <button className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="yXG2BeAYVGs" onClick={playVideo} >
                            <img src="/assets/images/icons/video_icon_white.svg" alt="white-play-icon" />
                            </button>
                            </>
                            :
                            <>
                            {
                            imageSrc ?
                            <img
                                src={imageSrc}
                                alt={altText}
                                />
                            :
                            <img
                                src={defaultImageSrc}
                                alt={altText} />
                            }
                            </>
                            }
                            <span className="overlay">
                            <img src="" alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="event_desc_main">
                            <div className="evt_category">CATEGORY:&nbsp;
                                {/*convertArrToStr(bannerInfo?.relationships?.category) || " " */}
                                <span>{bannerInfo?.field_event_type || convertArrToStr(bannerInfo?.relationships?.category) || " "}</span>
                            </div>
                            <h1 className="evt_title">{bannerInfo?.title || ""}</h1>
                            {
                            bannerInfo?.relationships?.brochure?.uri?.url &&
                            <div className="link_wrap">
                                <a aria-label={bannerInfo?.title || ""} href={bannerInfo.relationships.brochure.uri?.url} download>Download Brochure</a>
                            </div>
                            }
                            <div className="evt_time">
                                <img src="/assets/images/icons/icon_event_calendar.svg" alt="" className="icon" />
                                {bannerInfo?.eventDate && formatEventDate(bannerInfo.eventDate)}
                                {bannerInfo?.eventEndDate && ' - ' + formatEventDate(bannerInfo.eventEndDate)}
                                <br />
                                {
                                bannerInfo?.registrationDeadline && showHeroRegBtn && 
                                <>Registration Close On: {formatEventRegDate(bannerInfo.registrationDeadline)}</>
                                }
                            </div>
                            {
                            bannerInfo?.blurb &&
                            <div className="evt_small_desc">
                                {bannerInfo.blurb}
                            </div>
                            }
                            <div className="btn_list">
                                {showHeroRegBtn &&
                                <a className="btn btn-primary-solid"
                                    aria-label="Register Now"
                                    onClick={() => navigate(`/events-registration/?id=${bannerInfo?.drupal_internal__nid}`)}>
                                        Register Now
                                </a>}
                                {
                                bannerInfo?.path?.alias &&
                                <Link to={bannerInfo.path.alias} className="btn btn-primary" aria-label="Know more about the event">
                                Know More
                                </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        : null}
        <section className="section-pt section-pb">
            <div className="container">
                <div className="filter__wrap">
                    <ul className="nav nav-tabs filter__head" role="list">
                        {
                        filterOptions.map((el, index) => {
                        return (
                        <li role="listitem" className="nav-item" key={index}>
                            <a onClick={() => setFilter(el.id)} href="" className="nav-link filter__headitem">{el.label}</a>
                        </li>
                        )
                        })
                        }
                        <li className="nav-item filter_icon">
                            <a href=""> <img src="/assets/images/icons/icon_filter.svg" alt="" /> </a>
                        </li>
                    </ul>
                    <h2 className="section-heading">Upcoming Events
                        <span className="filter_icon"><img src="/assets/images/icons/icon_filter.svg" alt="" /></span>
                    </h2>
                    <EventsListingSection
                        parentCallback={(e) => parentCallback(e)}
                        key="futureEvents"
                        rowClasses=" "
                        columnClasses=" "
                        additionalParams={futureEventParams}
                        openVideoModal={openVideoModal}
                        />
                </div>
            </div>
        </section>
        <section className="section-bg section-py">
            <div className="container">
                <h2 className="section-heading">Past Events</h2>
                <EventsListingSection
                    key="pastEvents"
                    additionalParams={pastEventParams}
                    openVideoModal={openVideoModal}
                    setMetaImage={setMetaImage}
                    />
            </div>
        </section>
        <section className="section-pb bg-white archive_section">
            <div className="bg-primary">
                <div className="container">
                    {/* <a href="javascript:void(0)" onClick={clearData}>CLEAR</a> */}
                    <div className="row head_row">
                        <div className="col-md-2">
                            <h2>Archives</h2>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group select-dd select-specialities">
                                <label for="evt_specialities">Specialities</label>
                                <select
                                    name=""
                                    id="evt_specialities"
                                    className="form-control custom-select"
                                    placeholder={specPlaceholder}
                                    onChange={onCategorySelect}>
                                    {
                                        (specialities && specialities.length > 0) &&
                                        specialities.map((el, index) => {
                                            return <option key={index} value={el.name}>{el.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group select-dd select-dd-mm-yy">
                                <label for="chronological_order">Chronological Order</label>
                                <select
                                    name=""
                                    id="chronological_order"
                                    className="form-control custom-select"
                                    placeholder={selectedYear ? selectedYear : 'All Years'}
                                    onChange={onYearSelect}
                                    >
                                    {
                                    (yearsOptions && yearsOptions.length > 0) &&
                                    yearsOptions.map((el, index) => {
                                    return (
                                    <option
                                        key={index}
                                        value={el}
                                        >{el}</option>
                                    )
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group select-dd select-dd-mm-yy">
                                <label for="archieve_month">Month</label>
                                <select name="" id="archieve_month" onChange={onMonthChange} class="form-control custom-select" placeholder={selectedMonth ? monthsList.find(el => el?.id == selectedMonth)?.label : 'Month'}>
                                    {monthsList?.length
                                        ? monthsList.map(month => {
                                            return (
                                                <option key={month?.id} value={month?.id}>{month?.label}</option>
                                            )
                                        })
                                        : null}
                                    {/* <option name="January" value="01">Jan</option>
                                    <option name="February" value="02" selected="">Feb</option>
                                    <option name="March" value="03">Mar</option>
                                    <option name="April" value="04">Apr</option>
                                    <option name="May" value="05">May</option>
                                    <option name="June" value="06">Jun</option>
                                    <option name="July" value="07">Jul</option>
                                    <option name="August" value="08">Aug</option>
                                    <option name="September" value="09">Sep</option>
                                    <option name="November" value="10">Oct</option>
                                    <option name="November" value="11">Nov</option>
                                    <option name="December" value="12">Dec</option> */}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="archive_list">
                    {
                    archives && archives.length > 0 && !archiveLoading &&
                        archives.map((el, index) => {
                            return (
                                <div key={index} className="row">
                                    <div className="col-md-3">
                                        <div className="date">
                                            <span><img src="/assets/images/icons/icon_event_calendar.svg" alt=""  /></span>
                                            {(el?.eventDate) &&
                                            <> 
                                                {monthFromYear(el.eventDate)} <br />{dateToYear(el.eventDate)}
                                            </>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="archive_title">
                                            <p>{el?.title || ""}</p>
                                            <p className="evt_cat">CATEGORY:&nbsp;
                                                {el?.category || ""}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        {el?.path &&
                                        <div className="view_details">
                                            <Link to={el.path} className="btn btn-primary">
                                            View Details</Link>
                                        </div>}
                                    </div>
                                </div>
                            )
                        })
                    }
                    {archives && archives.length === 0 && !archiveLoading &&
                        <p>Currently there is no data</p>
                    }
                    {archiveLoading && <Loader loadingMessage="Loading Data..." />}
                </div>
                {
                (nextArchivesPage && !archiveLoading) &&
                <div className="btn_wrap text-center">
                    <LoadMoreButton
                        label="Load More"
                        callbackFunction={loadMoreData}
                        />
                </div>
                }
            </div>
            {
            loading &&
            <div className="col-lg-12">
                <Loader loadingMessage="Loading..." />
            </div>
            }
        </section>
        <NewsLetter
            />
    </main>
    <VideoModal
        videoURL={videoURL}
        showModal={showModal}
        closeModal={closeModal}
        />
</Layout>
)
}
export const query = graphql`
query heroEvent {
    allNodeEvent(
      filter: {field_keep_as_hero_event: {eq: true}}
      sort: {order: DESC, fields: created}
      limit: 1
    ) {
      edges {
        node {
          id
          field_paid_event
          field_event_type
          field_keep_as_hero_event
          drupal_internal__nid
          title
          path {
            alias
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          blurb: field_blurb
          video: field_video {
            url: input
          }
          overview: body {
            processed
          }
          eventDate: field_event_date
          eventEndDate: field_event_end_date
          registrationDeadline: field_event_registration_deadlin
          image: field_image {
            alt
          }
          relationships {
            image: field_image {
              id
              uri {
                value
                url
              }
            }
            image_mobile: field_image {
              id
              uri {
                value
                url
              }
            }
            brochure: field_brochure {
              id
              uri {
                value
                url
              }
            }
            category: field_event_speciality {
              ... on taxonomy_term__speciality {
                id
                name
              }
            }
          }
          field_paid_event
        }
      }
    }
    allNodeTemplatePages(filter: {path: {alias: {regex: "/events$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatags: metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
        }
      }
    }
  }
`
export default EventListing