import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import API from "../../../api/events"
import { initHeaders } from "../../../api/events"
import ShareButton from "../shareButton"
import ImageComponent from "../../pageSections/imageComponent"

import {
  compareDates,
  convertArrToStr,
  formatEventDate,
  formatEventRegDate,
  getCookie,
  getVideoThumbnail,
} from "../../../service/helper"

import { Link, navigate } from "gatsby"
import toast from "react-hot-toast"

const EventCard = props => {
  const { data, currentDateTime, isFuture, openVideoModal, setMetaImage } = props
  
  const location = useLocation()
  const [showRegisterBtn, setShowRegisterBtn] = useState(false)
  const defaultImage = "/assets/images/placeholder-348x233.png" // "https://img.youtube.com/vi/CxwJrzEdw1U/hqdefault.jpg"

  useEffect(() => {
    const videoImage = document.getElementById(`videoImage-${data.title}`)
    if (videoImage && videoImage.style) {
      videoImage.style.height = "233px"
      videoImage.style.width = "348px"
    }
    if(currentDateTime, data?.registrationDate, data?.paid_event) {
      console.log('Event registrationDeadline null --================',data)
      let result = compareDates(currentDateTime, data?.registrationDate) && data?.registrationDate !== null
      setShowRegisterBtn(result)
    }
  }, [data])

  const playVideo = () => {
    if (openVideoModal && data.video) {
      openVideoModal(data.video)
    }
  }

  const getVideoImage = videoURL => {
    if (videoURL && getVideoThumbnail(videoURL)) {
      return getVideoThumbnail(videoURL)
    }
    return null
  }

  const downloadResource = url => {
    let a = document.createElement("a")
    a.target = "_blank"
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = url
    a.download = data.title
    a.click()
    if (typeof window !== "undefined") {
      window.URL.revokeObjectURL(url)
    }
  }

  // event registration
  const registerForEvent = id => {
    if(showRegisterBtn) {
      navigate(`/events-registration/?id=${id}`)
    }
    // const token = getCookie("login_id")

    // if (token) {
    //   initHeaders()
    //   API.registerForEvent(id)
    //     .then(res =>

    //       toast.success(res.message)
    //     )
    //     .catch(err =>
    //       toast.error(err.message)
    //     )
    // } else {
    //   navigate(`/user/login?redirect_url=${location.pathname}`)
    // }
  }

  return (
    <div className="card event_card">
      <div className="img_video_wrapper">
        {/* 
        <ImageComponent
            content={data}
            />
        */}
        {!data.video ? (
          <img
            className="lazyload"
            id={`cardImage-${data.title}`}
            src={data?.image || defaultImage}
            alt={data?.title || ""}
          />
        ) : (
          <>
            <img
              className="lazyload"
              id={`videoImage-${data.title}`}
              src={getVideoImage(data.video) || defaultImage}
              alt={data?.title || ""}
            />
          </>
        )}
        {data?.video !== null && (
          <button
            className="video-popup"
            data-toggle="modal"
            data-target="#modal_video"
            data-src="yXG2BeAYVGs"
            onClick={playVideo}
          >
            <img
              src="/assets/images/icons/video_icon_white.svg"
              alt="white-play-icon"
            />
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="evt_category">
          CATEGORY:{" "}
          <span>
            {data?.type || ""} {data?.category?.length ? ' - ' + data?.category.join(', ') + '.' : ""}
          </span>
          <ShareButton type="event" url={data.path} setMetaImage={setMetaImage} image={ data?.image ||  getVideoImage(data.video)}/>
        </div>
        <h3 className="evt_title">{data?.title || ""}</h3>
        <p className="evt_small_desc" dangerouslySetInnerHTML={{ __html: data?.summary || data?.overview }}>
        </p>
        {data.brochure && (
          <div className="link_wrap">
            <a
              href="javascript:void(0)"
              onClick={() => downloadResource(data.brochure)}
            >
              Download Brochure
            </a>
          </div>
        )}
        {(
          <div className="evt_time">
            <img
              src="/assets/images/icons/icon_event_calendar.svg"
              alt=""
              className="icon"
            />
            {data.eventDate && formatEventDate(data.eventDate)}
            {data?.eventEndDate && ' - ' + formatEventDate(data.eventEndDate)}
            <br />
            {data.registrationDate && isFuture === true && showRegisterBtn === true && (
              <>
                Registration Close On:{" "}
                {formatEventRegDate(data.registrationDate)}
              </>
            )}
          </div>
        )}
      </div>
      <div className="card-link">
        {showRegisterBtn === true && (
          <a
            href="javascript:void(0)"
            className="btn btn-primary-solid"
            aria-label="Register Now"
            onClick={() => registerForEvent(data.nid)}
          >
            Register Now
          </a>
        )}
        {data.path && (
          <Link
            to={data.path}
            className="btn btn-primary"
            aria-label={data?.title || ""}
          >
            Know More
          </Link>
        )}
      </div>
    </div>
  )
}

export default EventCard
