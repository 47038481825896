import React, { useEffect, useState } from "react"
import moment from "moment"
import toast from "react-hot-toast"

import API from "../../../api/events"

import { objectDeepClone } from "../../../service/helper"

import EventCard from "../../addon/eventCard"
import LoadMoreButton from "../../addon/loadMoreButton"
import Loader from "../../addon/Loader"

const EventsListingSection  = (props) => {

    const {
        parentCallback,
        columnClasses,
        rowClasses,
        openVideoModal,
        additionalParams,
        setMetaImage
    } = props


    let [loading, setLoading] = useState(false)
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [totalCount, setTotalCount] = useState(0)
    let [data, setData] = useState([])
    let [currentDateTime, setCurrentDateTime] = useState(null)
    let [queryParams, setQueryParams] = useState({})

    data.sort(function(a, b){
        const date1 = moment(a?.eventDate, 'YYYY-MM-DD').valueOf()
        const date2 = moment(b?.eventDate, 'YYYY-MM-DD').valueOf()
        return date2 - date1;
    });
    useEffect(() => {
        setQueryParams({
            ...additionalParams
        })
        setData([])
    }, [additionalParams])

    useEffect(() => {
        getData()
    }, [queryParams])

    useEffect(() => {
        if(currentDateTime && parentCallback) parentCallback(currentDateTime)
    }, [currentDateTime])

    const loadMoreData = () => {
        setQueryParams({
            ...queryParams,
            'offset': queryParams['offset'] + queryParams['limit'] 
        })
    }
    const getData = () => {
        setLoading(true)
        if (Object.keys(queryParams).length > 0) {
            API.getEventsData(queryParams)
            .then(response => {
                console.log('response?.current_date_time',response)
                if(response?.current_date_time) setCurrentDateTime(response?.current_date_time)
                if (response && response.data.length > 0) {
                    let arr = objectDeepClone(data)
                    setData(arr.concat(response.data))
                    if (response.count) {
                        setTotalCount(Number(response.count))
                    }
                    setShowNoDataText(false)
                } else {
                    setData([])
                    setTotalCount(0)
                    setShowNoDataText(true)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Something went wrong")
            })
        }
    }
    
    return (
        <>
            <div className={`row ${rowClasses ? rowClasses : ""}`}>
                {
                    data && data.length > 0 &&
                    data.map((el, index) => {
                        return (
                            <div key={index} className={`col-lg-4 col-md-6 mb-5 ${columnClasses ? columnClasses : ""}`}>
                                <EventCard
                                    data={el}
                                    isFuture={additionalParams?.when  === 'future' ? true : false}
                                    openVideoModal={openVideoModal}
                                    setMetaImage={setMetaImage}
                                    currentDateTime={currentDateTime}
                                />
                            </div>
                        )
                    })
                }
            </div>
            {
                (data.length < totalCount && !loading) &&
                <div className="load_more_wrap text-center">
                    <LoadMoreButton
                        label="Load More"
                        callbackFunction={loadMoreData}
                    />
                </div>
            }
            {   loading === true &&
                <Loader loadingMesssage="Loading..." />
            }
            {
                (showNoDataText === true && !loading) &&
                <div className="load_more_wrap text-center">
                    <p>Currently there is no data</p>
                </div>
            }
        </>
    )
}

export default EventsListingSection
